<template>
  <div>
    <!--Entry-->
    <v-dialog v-model="show" scrollable persistent max-width="1400">
      <v-card flat class="white">
        <v-card-title class="text-h5 secondary--text"
          >Payment Information <span v-if="isDuplicate">&nbsp;(Duplicate)</span><v-spacer />
          <v-btn text rounded outlined @click="cancelEntry()"
            ><v-icon small left>fal fa-times</v-icon>Cancel</v-btn
          ></v-card-title
        >
        <v-card-text>
          <v-form ref="entryForm" v-model="valid" lazy-validation>
            <v-container fluid>
              <v-row dense>
                <!--Month Picker-->
                <v-col cols="12" md="2">
                  <v-autocomplete
                    :items="valueListsStore.months"
                    label="Payment Month"
                    placeholder=" "
                    persistent-placeholder
                    item-text="value"
                    item-value="id"
                    v-model="f_month"
                    clearable
                    clear-icon="fal fa-times-circle"
                  />
                </v-col>

                <!--Year-->
                <v-col cols="12" sm="12" md="2">
                  <v-text-field
                    v-model="d_year"
                    label="Payment Year"
                    placeholder=" "
                    persistent-placeholder
                  />
                </v-col>

                <!--Attendance Month-->
                <v-col cols="12" sm="12" md="2">
                  <text-display
                    :displayLabel="'Attendance Month'"
                    :displayString="$_monthNumToText(f_attendanceMonth)"
                  />
                </v-col>

                <!--Attendance Year-->
                <v-col cols="12" sm="12" md="2">
                  <text-display
                    :displayLabel="'Attendance Year'"
                    :displayNumber="d_attendanceYear ? d_attendanceYear : 0"
                  />
                </v-col>
              </v-row>

              <v-row dense>
                <!--Provider-->
                <v-col cols="12" sm="12" md="6">
                  <text-display
                    :displayLabel="'Provider Name'"
                    :displayString="d_providerNameLicense"
                  />
                </v-col>

                <!--Provider License Number-->
                <v-col cols="12" sm="12" md="2">
                  <text-display
                    :displayLabel="'License Number'"
                    :displayString="d_providerLicenseNumber"
                  />
                </v-col>

                <!--Provider Rating-->
                <v-col cols="12" sm="12" md="1">
                  <text-display :displayLabel="'Rating'" :displayString="d_providerRating" />
                </v-col>

                <!--Type-->
                <v-col cols="12" sm="12" md="1">
                  <text-display :displayLabel="'Type'" :displayString="d_providerType" />
                </v-col>

                <!--County-->
                <v-col cols="12" sm="12" md="2">
                  <text-display :displayLabel="'County'" :displayString="d_providerCounty" />
                </v-col>
              </v-row>

              <v-row dense>
                <!--Provider Business Name-->
                <v-col cols="12" sm="12" md="6">
                  <text-display
                    :displayLabel="'Provider Business Name'"
                    :displayString="d_providerNameFederal"
                  />
                </v-col>
                <!--Classroom BK-->
                <v-col cols="12" sm="2">
                  <text-display
                    :displayLabel="'Classroom BK'"
                    :displayString="$_yesNo(f_classroomBK)"
                  />
                </v-col>
                <!--Classroom-->
                <v-col cols="12" sm="2">
                  <text-display :displayLabel="'Classroom'" :displayString="d_classroom" />
                </v-col>
                <!--Provider Teacher Name-->
                <v-col cols="12" sm="2">
                  <text-display
                    :displayLabel="'Teacher Name'"
                    :displayString="d_classroomTeacherName"
                  />
                </v-col>
              </v-row>

              <v-row dense>
                <v-col cols="12" sm="12" md="2">
                  <!--Household ID-->
                  <text-display
                    :displayLabel="'Household ID'"
                    :displayNumber="fk_householdID ? fk_householdID : 0"
                  />
                </v-col>

                <v-col cols="12" sm="12" md="2">
                  <!--Client ID-->
                  <text-display
                    :displayLabel="'Client ID'"
                    :displayNumber="fk_clientID ? fk_clientID : 0"
                  />
                </v-col>

                <v-col cols="12" sm="12" md="1">
                  <!--Client ID-->
                  <text-display :displayLabel="'PreK Seat ID'" :displayString="d_prekSeatID" />
                </v-col>

                <v-col cols="12" sm="12" md="4">
                  <!--Client Name-->
                  <text-display
                    :displayLabel="'Client Name'"
                    :displayString="d_clientNameFirst + ' ' + d_clientNameLast"
                  />
                </v-col>

                <v-col cols="12" sm="12" md="2">
                  <!--Client Birth Date-->
                  <text-display :displayLabel="'Birth Date'" :displayString="d_clientDateBirth" />
                </v-col>

                <v-col cols="12" sm="12" md="1">
                  <!--Client Age-->
                  <text-display
                    :displayLabel="'Age'"
                    :displayNumber="d_clientAge ? d_clientAge : 0"
                  />
                </v-col>
              </v-row>

              <v-tabs class="pt-4" grow show-arrows="mobile" slider-color="secondary" v-model="tab">
                <v-tab>Details</v-tab>
                <v-tab>Care Plan & Attendance</v-tab>

                <!--Details-->
                <v-tab-item>
                  <v-card flat height="600">
                    <v-row dense>
                      <!--Left Side-->
                      <v-col cols="12" md="8">
                        <v-row dense>
                          <!--Internal Note-->
                          <v-col cols="12" sm="12">
                            <v-textarea
                              rows="2"
                              v-model="d_noteInternal"
                              label="Internal Note"
                              placeholder=" "
                              persistent-placeholder
                            />
                          </v-col>
                        </v-row>

                        <v-row dense>
                          <!--External Note-->
                          <v-col cols="12" sm="12">
                            <v-textarea
                              rows="2"
                              v-model="d_noteExternal"
                              label="External Note"
                              placeholder=" "
                              persistent-placeholder
                            />
                          </v-col>
                        </v-row>

                        <v-row dense>
                          <!--Follow Up-->
                          <v-col cols="12" sm="12" md="8">
                            <v-autocomplete
                              :items="valueListsStore.valueListItems[25]"
                              item-text="d_name"
                              item-value="id"
                              label="Follow Up Reason"
                              placeholder=" "
                              persistent-placeholder
                              v-model="f_followup"
                              key="id"
                              clearable
                              clear-icon="fal fa-times-circle"
                            />
                          </v-col>
                        </v-row>

                        <v-row dense>
                          <!--Type-->
                          <v-col cols="12" sm="4">
                            <v-autocomplete
                              :items="valueListsStore.paymentType"
                              item-text="value"
                              item-value="id"
                              label="Type"
                              placeholder=" "
                              persistent-placeholder
                              v-model="f_type"
                              key="id"
                              clearable
                              clear-icon="fal fa-times-circle"
                            />
                          </v-col>

                          <!--Status-->
                          <v-col cols="12" sm="4">
                            <v-autocomplete
                              v-if="usersStore.authUser.f_paymentStatus === 1"
                              :items="valueListsStore.paymentStatus"
                              item-text="value"
                              item-value="id"
                              label="Status"
                              placeholder=" "
                              persistent-placeholder
                              v-model="f_status"
                              key="id"
                              clearable
                              clear-icon="fal fa-times-circle"
                            />
                            <text-display
                              v-else
                              :displayLabel="'Status'"
                              :displayString="$_paymentStatus(f_status)"
                            />
                          </v-col>
                          <!--Remove Payment-->
                          <v-col cols="12" sm="12" md="3">
                            <v-select
                              v-if="mode !== 1 && usersStore.authUser.f_admin === 1"
                              :items="[{ text: 'Yes', value: '0' }]"
                              item-text="text"
                              item-value="value"
                              label="Remove Payment?"
                              placeholder=" "
                              persistent-placeholder
                              v-model="f_show"
                              clearable
                              clear-icon="fal fa-times-circle"
                            />
                          </v-col>
                        </v-row>

                        <v-row dense v-if="f_type === 2 && 1 === 2">
                          <!--Applied To Payment-->
                          <v-col cols="12" sm="4">
                            <v-autocomplete
                              :items="valueListsStore.yesNo"
                              item-text="value"
                              item-value="id"
                              label="Applied To Payment"
                              placeholder=" "
                              persistent-placeholder
                              v-model="f_appliedToPayment"
                              key="id"
                              clearable
                              clear-icon="fal fa-times-circle"
                            />
                          </v-col>
                        </v-row>

                        <v-row class="pb-4">
                          <v-col cols="12" sm="12">
                            <span class="text-caption grey--text"
                              >Created: {{ $_formatTimestamp(created_at) }} &#8226; Modified:
                              {{ $_formatTimestamp(updated_at) }}</span
                            >
                          </v-col>
                        </v-row>
                      </v-col>

                      <v-col cols="12" md="1"> </v-col>

                      <!--Right Side-->
                      <v-col cols="12" md="3">
                        <v-row dense> </v-row>

                        <v-row dense>
                          <v-col cols="12" sm="12" md="6">
                            <!--Tuition-->
                            <text-display
                              :displayLabel="'Tuition'"
                              :displayString="$_formatMoney(d_amountTuition)"
                            />
                          </v-col>

                          <v-col cols="12" sm="12" md="6">
                            <!--Rate-->
                            <text-display
                              :displayLabel="'Rate'"
                              :displayString="$_formatMoney(d_amountRate)"
                            />
                          </v-col>
                        </v-row>

                        <v-row dense>
                          <v-col cols="12" sm="12" md="6">
                            <!--Initial Reimbursement-->
                            <text-display
                              :displayLabel="'Initial Reimbursement'"
                              :displayString="$_formatMoney(d_amountInitialReimbursement)"
                            />
                          </v-col>

                          <v-col cols="12" sm="12" md="6">
                            <!--Time Percentage-->
                            <text-display
                              :displayLabel="'Time Percentage'"
                              :displayString="$_formatPercentage(d_timePercentage)"
                            />
                          </v-col>
                        </v-row>

                        <v-row dense>
                          <v-col cols="12" sm="12" md="6">
                            <!--Reimbursement-->
                            <text-display
                              :displayLabel="'Reimbursement'"
                              :displayString="$_formatMoney(d_amountReimbursement)"
                            />
                          </v-col>

                          <v-col cols="12" sm="12" md="6">
                            <!--Parent Fee-->
                            <text-display
                              :displayLabel="'Parent Fee'"
                              :displayString="$_formatMoney(d_amountParentFee)"
                            />
                          </v-col>
                        </v-row>

                        <v-row dense>
                          <v-col cols="12" sm="12" md="6">
                            <!--Attendance Prorated Days-->
                            <text-display
                              :displayLabel="'Prorated Days'"
                              :displayNumber="
                                d_attendanceDaysProrated ? d_attendanceDaysProrated : 0
                              "
                            />
                          </v-col>

                          <v-col cols="12" sm="12" md="6">
                            <!--Attendance Prorated Amount-->
                            <text-display
                              :displayLabel="'Prorated Amount'"
                              :displayString="d_amountProrated"
                            />
                          </v-col>
                        </v-row>

                        <v-row dense>
                          <v-col cols="12" sm="12" md="6">
                            <!--Prorated Days-->
                            <v-text-field
                              v-model="d_attendanceDaysProratedOverride"
                              label="Override Prorated Days"
                              placeholder=" "
                              persistent-placeholder
                            />
                          </v-col>
                          <v-col cols="12" sm="12" md="6">
                            <!--Prorated Amount-->
                            <text-display
                              :displayLabel="'Override Prorated Amount'"
                              :displayString="amountProrated"
                            />
                          </v-col>
                        </v-row>

                        <v-row dense>
                          <v-col cols="12" sm="12" md="6">
                            <!--Override Amount-->
                            <v-text-field
                              v-model="d_amountOverride"
                              label="Override Amount"
                              placeholder=" "
                              persistent-placeholder
                            />
                          </v-col>

                          <v-col cols="12" sm="12" md="6">
                            <!--Total Amount-->
                            <text-display
                              :displayBold="true"
                              :displayLabel="'Payment Amount'"
                              :displayString="$_formatMoney(paymentAmount)"
                            />
                          </v-col>
                        </v-row>

                        <v-row dense>
                          <v-col cols="12" sm="12">
                            <!--Funding Source - Contract-->
                            <text-display
                              :displayLabel="'Funding Source - Care Plan'"
                              :displayString="d_fundingSource_Contract"
                            />
                          </v-col>
                        </v-row>

                        <v-row dense>
                          <!--Funding Source - Payment-->
                          <v-col cols="12" sm="12">
                            <v-autocomplete
                              :items="fundingSourcesStore.fundingSourcesValueList"
                              label="Funding Source - Payment"
                              placeholder=" "
                              persistent-placeholder
                              item-text="d_name"
                              item-value="id"
                              v-model="fk_fundingSourceID_Payment"
                              clearable
                              clear-icon="fal fa-times-circle"
                              @input="pullFundingSource(fk_fundingSourceID_Payment)"
                            />
                          </v-col>
                        </v-row>

                        <v-row dense>
                          <v-col cols="12" sm="12" md="4">
                            <!--CCDF/TANF Display-->
                            <text-display
                              v-if="fk_fundingSourceID_Payment === fk_fundingSourceID_Contract"
                              :displayLabel="'CCDF/TANF'"
                              :displayString="d_CCDFTANF"
                            />
                            <!--CCDF/TANF Entry-->
                            <v-autocomplete
                              v-else
                              :items="valueListsStore.yesNo"
                              label="CCDF/TANF"
                              placeholder=" "
                              persistent-placeholder
                              item-text="value"
                              item-value="value"
                              v-model="d_CCDFTANF"
                              clearable
                              clear-icon="fal fa-times-circle"
                            />
                          </v-col>
                          <v-col cols="12" sm="12" md="8">
                            <!--Funding Code-->
                            <text-display
                              v-if="fk_fundingSourceID_Payment === fk_fundingSourceID_Contract"
                              :displayLabel="'Funding Code'"
                              :displayString="d_fundingCode"
                            />
                            <v-text-field
                              v-else
                              v-model="d_fundingCode"
                              label="Funding Code"
                              placeholder=" "
                              persistent-placeholder
                            />
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                  </v-card>
                </v-tab-item>

                <!--Care Plan & Attendance-->
                <v-tab-item>
                  <v-card flat height="600">
                    <v-row>
                      <v-col cols="12" sm="12">
                        <span class="text-h6 secondary--text">Care Plan</span>
                      </v-col>
                    </v-row>

                    <v-row>
                      <!--Certification Start Date-->
                      <v-col cols="12" sm="6" md="3">
                        <text-display
                          :displayLabel="'Certification Start Date'"
                          :displayString="d_carePlanCertificationDateStart"
                        />
                      </v-col>

                      <!--Certification End Date-->
                      <v-col cols="12" sm="6" md="3">
                        <text-display
                          :displayLabel="'Certification End Date'"
                          :displayString="d_carePlanCertificationDateEnd"
                        />
                      </v-col>

                      <!--Certification Start Date-->
                      <v-col cols="12" sm="6" md="3">
                        <text-display
                          :displayLabel="'Start Date'"
                          :displayString="d_carePlanItemDateStart"
                        />
                      </v-col>

                      <!--Care Plan Item Eligibility Check-In Date-->
                      <v-col cols="12" sm="6" md="3">
                        <text-display
                          :displayLabel="'Eligibility Check-In Date'"
                          :displayString="d_carePlanItemDateEligibilityCheck"
                        />
                      </v-col>
                    </v-row>

                    <v-row>
                      <!--Eligibility Reason-->
                      <v-col cols="12" sm="12">
                        <text-display
                          :displayLabel="'Eligibilty Reason'"
                          :displayString="d_carePlanItemEligibilityReason"
                        />
                      </v-col>
                    </v-row>

                    <v-row>
                      <!--Program-->
                      <v-col cols="12" sm="12" md="6">
                        <text-display :displayLabel="'Program'" :displayString="d_program" />
                      </v-col>

                      <!--Program Option-->
                      <v-col cols="12" sm="12" md="6">
                        <text-display
                          :displayLabel="'Program Option'"
                          :displayString="d_programOption"
                        />
                      </v-col>
                    </v-row>

                    <v-row>
                      <!--Family Size-->
                      <v-col cols="12" sm="12" md="2">
                        <text-display
                          :displayLabel="'Family Size'"
                          :displayNumber="d_carePlanItemFamilySize ? d_carePlanItemFamilySize : 0"
                        />
                      </v-col>
                      <!--Time-->
                      <v-col cols="12" sm="12" md="3">
                        <text-display :displayLabel="'Time'" :displayString="d_carePlanItemTime" />
                      </v-col>
                      <!--Income-->
                      <v-col cols="12" sm="12" md="3">
                        <text-display
                          :displayLabel="'Income'"
                          :displayString="$_formatMoney(d_amountIncome)"
                        />
                      </v-col>
                      <!--FPL-->
                      <v-col cols="12" sm="12" md="2">
                        <text-display
                          :displayLabel="'FPL'"
                          :displayString="$_formatPercentage(d_carePlanItemFPL)"
                        />
                      </v-col>
                      <!--SMI-->
                      <v-col cols="12" sm="12" md="2">
                        <text-display
                          :displayLabel="'SMI'"
                          :displayString="$_formatPercentage(d_carePlanItemSMI)"
                        />
                      </v-col>
                    </v-row>

                    <v-row dense>
                      <!--Placeholder-->
                      <v-col cols="12" sm="12" md="3">
                        <text-display
                          :displayLabel="'Placeholder'"
                          :displayString="$_yesNo(f_placeholder)"
                        />
                      </v-col>
                    </v-row>

                    <v-divider />

                    <v-row>
                      <v-col cols="12" sm="12">
                        <span class="text-h6 secondary--text">Attendance</span>
                      </v-col>
                    </v-row>

                    <v-row>
                      <!--Attendance Month-->
                      <v-col cols="12" sm="12" md="2">
                        <text-display
                          :displayLabel="'Month'"
                          :displayString="$_monthNumToText(f_attendanceMonth)"
                        />
                      </v-col>

                      <!--Attendance Year-->
                      <v-col cols="12" sm="12" md="2">
                        <text-display
                          :displayLabel="'Year'"
                          :displayNumber="d_attendanceYear ? d_attendanceYear : 0"
                        />
                      </v-col>
                    </v-row>

                    <v-row dense>
                      <!--Attendance Present-->
                      <v-col cols="12" sm="12" md="2">
                        <text-display
                          :displayLabel="'Days Present'"
                          :displayNumber="d_attendanceDaysPresent ? d_attendanceDaysPresent : 0"
                        />
                      </v-col>

                      <!--Attendance Absent-->
                      <v-col cols="12" sm="12" md="2">
                        <text-display
                          :displayLabel="'Days Absent'"
                          :displayNumber="d_attendanceDaysAbsent ? d_attendanceDaysAbsent : 0"
                        />
                      </v-col>

                      <!--Attendance Closed-->
                      <v-col cols="12" sm="12" md="2">
                        <text-display
                          :displayLabel="'Days Closed'"
                          :displayNumber="d_attendanceDaysClosed ? d_attendanceDaysClosed : 0"
                        />
                      </v-col>

                      <!--Attendance Total Days-->
                      <v-col cols="12" sm="12" md="2">
                        <text-display
                          :displayLabel="'Total Days'"
                          :displayNumber="d_attendanceDaysTotal ? d_attendanceDaysTotal : 0"
                        />
                      </v-col>

                      <!--Attendance Prorated Days-->
                      <v-col cols="12" sm="12" md="2">
                        <text-display
                          :displayLabel="'Prorated Days'"
                          :displayNumber="d_attendanceDaysProrated ? d_attendanceDaysProrated : 0"
                        />
                      </v-col>

                      <!--Attendance Prorated Amount-->
                      <v-col cols="12" sm="12" md="2">
                        <text-display
                          :displayLabel="'Prorated Amount'"
                          :displayString="d_amountProrated"
                        />
                      </v-col>
                    </v-row>
                  </v-card>
                </v-tab-item>
              </v-tabs>
            </v-container>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-btn block rounded color="primary" @click="saveEntry()"
            ><v-icon small left>fal fa-check</v-icon>Submit</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { createHelpers } from "vuex-map-fields";
import { mixin } from "@/mixins/mixin.js";
import TextDisplay from "@/components/TextDisplay";

const { mapFields } = createHelpers({
  getterType: "paymentsStore/getEntryField",
  mutationType: "paymentsStore/updateEntryField"
});

export default {
  name: "PaymentEntry",
  mixins: [mixin],
  computed: {
    ...mapState({
      clientsStore: state => state.clientsStore,
      paymentsStore: state => state.paymentsStore,
      providersStore: state => state.providersStore,
      valueListsStore: state => state.valueListsStore,
      fundingSourcesStore: state => state.fundingSourcesStore,
      usersStore: state => state.usersStore
    }),
    ...mapFields([
      "show",
      "show2",
      "valid",
      "mode",
      "id",
      "tab",
      "isDuplicate",

      "fk_providerID",
      "fk_classroomID",
      "fk_householdID",
      "fk_applicantID",
      "fk_clientID",
      "fk_carePlanItemID",
      "fk_attendanceItemID",
      "fk_programID",
      "fk_programOptionID",
      "fk_fundingSourceID_Contract",
      "fk_fundingSourceID_Payment",
      "d_fundingSource_Contract",
      "d_fundingSource_Payment",
      "d_program",
      "d_programOption",
      "d_providerNameLicense",
      "d_providerNameFederal",
      "d_providerLicenseNumber",
      "d_providerRating",
      "d_providerType",
      "d_providerCounty",
      "d_classroom",
      "f_classroomBK",
      "d_classroomTeacherName",
      "d_primaryApplicantNameFirst",
      "d_primaryApplicantNameLast",
      "d_primaryApplicantDateBirth",
      "d_prekSeatID",
      "d_clientNameFirst",
      "d_clientNameLast",
      "d_clientDateBirth",
      "d_clientAge",
      "d_childNumber",
      "d_language",
      "d_additionalInfoSpecialNeed",
      "d_applicantCounty",
      "d_carePlanCertificationDateStart",
      "d_carePlanCertificationDateEnd",
      "d_carePlanItemDateStart",
      "d_carePlanItemDateEligibilityCheck",
      "d_carePlanItemFPL",
      "d_carePlanItemSMI",
      "d_carePlanItemFamilySize",
      "d_carePlanItemTime",
      "d_carePlanItemEligibilityReason",
      "f_attendanceMonth",
      "d_attendanceYear",
      "d_attendanceDaysPresent",
      "d_attendanceDaysAbsent",
      "d_attendanceDaysClosed",
      "d_attendanceDaysTotal",
      "d_attendanceDaysProrated",
      "d_attendanceDaysProratedOverride",
      "d_noteInternal",
      "d_noteExternal",
      "d_CCDFTANF",
      "d_fundingCodeSource",
      "d_fundingCodeSuffix",
      "d_fundingCode",
      "f_month",
      "d_year",
      "d_amountTuition",
      "d_amountRate",
      "d_amountInitialReimbursement",
      "d_amountReimbursement",
      "d_amountParentFee",
      "d_amountIncome",
      "d_amountOverride",
      "d_timePercentage",
      "d_amountProrated",
      "d_amount",
      "f_followup",
      "f_status",
      "f_type",
      "f_show",
      "f_placeholder",
      "f_appliedToPayment",
      "date1",
      "date2",
      "date3",
      "date4",
      "date5",
      "date6",
      "created_at",
      "updated_at"
    ]),
    amountProrated() {
      const initialAmount =
        (Math.round((21.67 / this.d_attendanceDaysTotal) * 10000) / 10000) *
        this.d_attendanceDaysProratedOverride;
      return initialAmount.toFixed(4);
    },
    paymentAmount() {
      if (this.d_amountOverride < 0 || this.d_amountOverride > 0) {
        return this.d_amountOverride;
      }
      if (this.d_attendanceDaysProratedOverride > 0) {
        const calculationAmount = this.d_amountReimbursement - this.d_amountParentFee;
        const amountDaily = calculationAmount / 21.67;
        return Math.round(amountDaily * this.amountProrated * 100) / 100;
      }
      if (this.d_attendanceDaysProrated > 0) {
        const calculationAmount = this.d_amountReimbursement - this.d_amountParentFee;
        const amountDaily = calculationAmount / 21.67;
        return Math.round(amountDaily * this.d_amountProrated * 100) / 100;
      }
      return this.d_amountReimbursement - this.d_amountParentFee;
    }
  },
  components: {
    TextDisplay
  },
  data() {
    return {
      menu: false,
      menu2: false,
      menu3: false,
      menu4: false,
      menu5: false,
      menu6: false
    };
  },
  watch: {
    date1() {
      this.d_clientDateBirth = this.$_formatDate(this.date1);
    },
    date2() {
      this.d_primaryApplicantDateBirth = this.$_formatDate(this.date2);
    },
    date3() {
      this.d_carePlanCertificationDateStart = this.$_formatDate(this.date3);
    },
    date4() {
      this.d_carePlanCertificationDateEnd = this.$_formatDate(this.date4);
    },
    date5() {
      this.d_carePlanItemDateStart = this.$_formatDate(this.date5);
    },
    date6() {
      this.d_carePlanItemDateEligibilityCheck = this.$_formatDate(this.date6);
    }
  },
  methods: {
    resetEntry() {
      this.$store.dispatch("paymentsStore/resetEntry");
    },

    async loadValueLists() {
      await this.$store.dispatch("providersStore/valueList");
    },

    async pullFundingSource(id) {
      await this.$store.dispatch("fundingSourcesStore/read", id);
      const fundingSource = await this.$store.getters["fundingSourcesStore/getFundingSource"];
      this.d_fundingCodeSource = fundingSource.d_code;
      this.d_fundingCodeSuffix = "00";
      this.d_fundingCode = this.d_fundingCodeSource + "-" + this.d_fundingCodeSuffix;
      //this.d_fundingCode = fundingSource.d_code;
    },

    cancelEntry() {
      this.show = false;
      this.resetEntry();
    },

    async saveEntry() {
      if (this.$refs.entryForm.validate()) {
        this.d_amount = this.paymentAmount;
        if (this.amountProrated > 0) {
          this.d_amountProrated = this.amountProrated;
        }
        const entry = this.$store.getters["paymentsStore/getEntry"];
        /* Create */
        if (this.mode === 1) {
          await this.$store.dispatch("paymentsStore/create", entry);
          /* Grab filters */
          const filters = await this.$store.getters["paymentsStore/getFilters"];
          /* Records based on filters */
          await this.$store.dispatch("paymentsStore/fetch", filters);
        } else {
          /* Edit */
          await this.$store.dispatch("paymentsStore/update", entry);
        }
        this.cancelEntry();
        /* Reload List */
        this.$store.dispatch("progressStore/set", true);
        const filters = this.$store.getters["paymentsStore/getFilters"];
        await this.$store.dispatch("paymentsStore/fetch", filters);
        this.$store.dispatch("progressStore/set", false);
      }
    }
  }
};
</script>

<style scoped>
.clickable {
  cursor: pointer;
}
button {
  outline: none;
}
</style>
