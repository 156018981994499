<template>
  <div>
    <!--Title Bar-->
    <title-bar v-if="paymentSection === 1" :title-value="'Payments'"></title-bar>

    <!-- Title Bar For Section List-->
    <v-card v-else flat>
      <v-toolbar dense flat color="secondary" class="mb-2" dark>
        <v-icon>fal fa-money-bill-alt</v-icon>
        <v-toolbar-title class="pl-2">Payments</v-toolbar-title>
      </v-toolbar>
    </v-card>

    <!-- Filter -->
    <v-toolbar flat dense>
      <v-menu v-model="filterMenu" offset-y right :close-on-content-click="false">
        <template #activator="{ on }">
          <v-btn rounded color="primary" v-on="on"
            ><v-icon small left>fal fa-filter</v-icon><span v-if="showFiltered()">Filtered</span
            ><span v-else>Filter</span>
          </v-btn>
        </template>

        <v-card width="700px">
          <v-card-text>
            <v-container fluid grid-list-sm>
              <v-row dense>
                <v-col cols="12" sm="12" v-if="showFiltered()">
                  <v-btn block rounded color="primary" @click="clearFilter()">
                    <v-icon small left>fal fa-times</v-icon>Clear Filters
                  </v-btn>
                </v-col>
              </v-row>

              <v-row dense>
                <!--Left Side-->
                <v-col cols="12" sm="12" md="6">
                  <v-row dense>
                    <!--ID-->
                    <v-col cols="12" sm="12">
                      <v-text-field
                        v-model="id"
                        label="Payment ID"
                        placeholder=" "
                        persistent-placeholder
                      />
                    </v-col>
                  </v-row>

                  <v-row v-if="paymentSection !== 2" dense>
                    <!--Provider License-->
                    <v-col cols="12" sm="12">
                      <v-text-field
                        v-model="d_licenseNumber"
                        label="Provider License #"
                        placeholder=" "
                        persistent-placeholder
                      />
                    </v-col>
                  </v-row>

                  <v-row v-if="paymentSection !== 2" dense>
                    <!--Provider Name-->
                    <v-col cols="12" sm="12">
                      <v-text-field
                        v-model="d_nameLicense"
                        label="Provider Name"
                        placeholder=" "
                        persistent-placeholder
                      />
                    </v-col>
                  </v-row>

                  <v-row v-if="paymentSection !== 3" dense>
                    <!--Household ID-->
                    <v-col cols="12" sm="6">
                      <v-text-field
                        v-model="fk_householdID"
                        label="Household ID"
                        placeholder=" "
                        persistent-placeholder
                      />
                    </v-col>

                    <!--PreK Seat ID-->
                    <v-col cols="12" sm="6">
                      <v-text-field
                        v-model="d_prekSeatID"
                        label="PreK Seat ID"
                        placeholder=" "
                        persistent-placeholder
                      />
                    </v-col>
                  </v-row>

                  <v-row v-if="paymentSection !== 3" dense>
                    <!--Client ID-->
                    <v-col cols="12" sm="6">
                      <v-text-field
                        v-model="clientID"
                        label="Client ID"
                        placeholder=" "
                        persistent-placeholder
                      />
                    </v-col>
                    <!--v1 Child ID-->
                    <v-col cols="12" sm="6">
                      <v-text-field
                        v-model="d_childIDv1"
                        label="v1 Child ID"
                        placeholder=" "
                        persistent-placeholder
                      />
                    </v-col>
                  </v-row>

                  <v-row v-if="paymentSection !== 3" dense>
                    <!--Client First Name-->
                    <v-col cols="12" sm="12">
                      <v-text-field
                        v-model="d_nameFirst"
                        label="Client First Name"
                        placeholder=" "
                        persistent-placeholder
                      />
                    </v-col>
                  </v-row>

                  <v-row v-if="paymentSection !== 3" dense>
                    <!--Client Last Name-->
                    <v-col cols="12" sm="12">
                      <v-text-field
                        v-model="d_nameLast"
                        label="Client Last Name"
                        placeholder=" "
                        persistent-placeholder
                      />
                    </v-col>
                  </v-row>

                  <v-row dense>
                    <!--Follow Up-->
                    <v-col cols="12" sm="12">
                      <v-autocomplete
                        :items="valueListsStore.valueListItems[25]"
                        item-text="d_name"
                        item-value="id"
                        label="Follow Up Reason"
                        placeholder=" "
                        persistent-placeholder
                        v-model="f_followup"
                        key="id"
                        clearable
                        clear-icon="fal fa-times-circle"
                      />
                    </v-col>
                  </v-row>

                  <v-row dense>
                    <!--No Follow Up-->
                    <v-col class="pt-0 pb-4" cols="12" sm="12">
                      <label class="grey--text text--darken-1 text-caption mb-0 pb-0"
                        >No Follow Up Required</label
                      >
                      <v-checkbox
                        v-model="noFollowup"
                        label=""
                        value="Yes"
                        color="primary"
                        class="pr-2 mt-1 pt-0"
                        hide-details
                      >
                      </v-checkbox>
                    </v-col>
                  </v-row>

                  <v-row dense>
                    <!--Status-->
                    <v-col cols="12" sm="12">
                      <v-select
                        :items="valueListsStore.paymentStatus"
                        item-text="value"
                        item-value="id"
                        label="Payment Status"
                        placeholder=" "
                        persistent-placeholder
                        v-model="f_status"
                        clearable
                        clear-icon="fal fa-times-circle"
                      />
                    </v-col>
                  </v-row>
                </v-col>

                <!--Right Side-->
                <v-col cols="12" sm="12" md="6">
                  <v-row dense>
                    <!--Payment Month-->
                    <v-col cols="12" sm="7">
                      <v-select
                        :items="valueListsStore.months"
                        item-text="value"
                        item-value="id"
                        label="Payment Month"
                        placeholder=" "
                        persistent-placeholder
                        v-model="f_month"
                        clearable
                        clear-icon="fal fa-times-circle"
                      />
                    </v-col>

                    <!--Payment Year-->
                    <v-col cols="12" sm="5">
                      <v-select
                        :items="yearsStore.yearsValueList"
                        item-text="d_yearStart"
                        item-value="d_yearStart"
                        label="Payment Year"
                        placeholder=" "
                        persistent-placeholder
                        v-model="d_year"
                        clearable
                        clear-icon="fal fa-times-circle"
                      />
                    </v-col>
                  </v-row>

                  <v-row dense>
                    <!--Range Start Date-->
                    <v-col cols="12" sm="6">
                      <v-menu
                        :close-on-content-click="false"
                        v-model="menu3"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                      >
                        <template #activator="{ on }">
                          <v-text-field
                            v-on="on"
                            v-model="dateStart"
                            label="Range Start Date"
                            placeholder=" "
                            persistent-placeholder
                            prepend-icon="fal fa-calendar-alt"
                            required
                            @blur="date3 = $_parseDateMonth(dateStart)"
                          />
                        </template>
                        <v-date-picker
                          type="month"
                          v-model="date3"
                          @input="menu3 = false"
                          color="primary"
                        ></v-date-picker>
                      </v-menu>
                    </v-col>

                    <!--Range End Date-->
                    <v-col cols="12" sm="6">
                      <v-menu
                        :close-on-content-click="false"
                        v-model="menu4"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                      >
                        <template #activator="{ on }">
                          <v-text-field
                            v-on="on"
                            v-model="dateEnd"
                            label="Range End Date"
                            placeholder=" "
                            persistent-placeholder
                            prepend-icon="fal fa-calendar-alt"
                            required
                            @blur="date4 = $_parseDateMonth(dateEnd)"
                          />
                        </template>
                        <v-date-picker
                          type="month"
                          v-model="date4"
                          @input="menu4 = false"
                          color="primary"
                        ></v-date-picker>
                      </v-menu>
                    </v-col>
                  </v-row>

                  <v-row dense>
                    <!--Start Date-->
                    <v-col cols="12" sm="12">
                      <v-menu
                        :close-on-content-click="false"
                        v-model="menu"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                      >
                        <template #activator="{ on }">
                          <v-text-field
                            v-on="on"
                            v-model="d_dateStart"
                            label="Care Plan Start Date"
                            placeholder=" "
                            persistent-placeholder
                            prepend-icon="fal fa-calendar-alt"
                            required
                            @blur="date1 = $_parseDate(d_dateStart)"
                          />
                        </template>
                        <v-date-picker
                          v-model="date1"
                          @input="menu = false"
                          color="primary"
                        ></v-date-picker>
                      </v-menu>
                    </v-col>
                  </v-row>

                  <v-row dense>
                    <!--Eligibility Check In Date-->
                    <v-col cols="12" sm="12">
                      <v-menu
                        :close-on-content-click="false"
                        v-model="menu2"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                      >
                        <template #activator="{ on }">
                          <v-text-field
                            v-on="on"
                            v-model="d_dateEnd"
                            label="Eligibility Check In Date"
                            placeholder=" "
                            persistent-placeholder
                            prepend-icon="fal fa-calendar-alt"
                            required
                            @blur="date2 = $_parseDate(d_dateEnd)"
                          />
                        </template>
                        <v-date-picker
                          v-model="date2"
                          @input="menu2 = false"
                          color="primary"
                        ></v-date-picker>
                      </v-menu>
                    </v-col>
                  </v-row>

                  <v-row dense>
                    <!--Program-->
                    <v-col cols="12" sm="12">
                      <v-autocomplete
                        :items="programsStore.programsValueList"
                        label="Program"
                        placeholder=" "
                        persistent-placeholder
                        item-text="d_name"
                        item-value="id"
                        v-model="fk_programID"
                        clearable
                        clear-icon="fal fa-times-circle"
                        @input="pullProgramOptions(fk_programID)"
                      />
                    </v-col>
                  </v-row>

                  <v-row
                    dense
                    v-if="
                      (fk_programID && programsStore.optionsValueList.length > 0) ||
                        (fk_programID && fk_programOptionID)
                    "
                  >
                    <!--Program Options-->
                    <v-col cols="12" sm="12">
                      <v-autocomplete
                        :items="programsStore.optionsValueList"
                        item-text="d_name"
                        item-value="id"
                        label="Program Option"
                        placeholder=" "
                        persistent-placeholder
                        v-model="fk_programOptionID"
                        key="id"
                        clearable
                        clear-icon="fal fa-times-circle"
                      />
                    </v-col>
                  </v-row>

                  <v-row v-else dense>
                    <text-display
                      :displayLabel="'Program Option'"
                      :displayString="'Select Program First'"
                    />
                  </v-row>

                  <v-row dense>
                    <!--Attendance Month-->
                    <v-col cols="12" sm="7">
                      <v-select
                        :items="valueListsStore.months"
                        item-text="value"
                        item-value="id"
                        label="Attendance Month"
                        placeholder=" "
                        persistent-placeholder
                        v-model="f_attendanceMonth"
                        clearable
                        clear-icon="fal fa-times-circle"
                      />
                    </v-col>

                    <!--Attendance Year-->
                    <v-col cols="12" sm="5">
                      <v-select
                        :items="yearsStore.yearsValueList"
                        item-text="d_yearStart"
                        item-value="d_yearStart"
                        label="Attendance Year"
                        placeholder=" "
                        persistent-placeholder
                        v-model="d_attendanceYear"
                        clearable
                        clear-icon="fal fa-times-circle"
                      />
                    </v-col>
                  </v-row>

                  <v-row dense>
                    <!--Funding Source Code-->
                    <v-col cols="12" sm="12">
                      <v-autocomplete
                        :items="fundingSourcesStore.fundingSourcesValueList"
                        label="Funding Source Code"
                        placeholder=" "
                        persistent-placeholder
                        item-text="d_code"
                        item-value="d_code"
                        v-model="d_fundingCodeSource"
                        clearable
                        clear-icon="fal fa-times-circle"
                      />
                    </v-col>
                  </v-row>

                  <v-row dense>
                    <!--Type-->
                    <v-col cols="12" sm="12">
                      <v-select
                        :items="valueListsStore.paymentType"
                        item-text="value"
                        item-value="id"
                        label="Type"
                        placeholder=" "
                        persistent-placeholder
                        v-model="f_type"
                        clearable
                        clear-icon="fal fa-times-circle"
                      />
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer />
            <v-btn block rounded color="primary" @click="filter()">
              <v-icon small left>fal fa-check</v-icon>Submit
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-menu>

      <v-spacer />

      <payment-entry />

      <!--Report Date Picker Dialog for Check Request-->
      <report-date-picker
        v-if="1 === 2"
        :datePickerSection="paymentSection === 2 ? 2 : 0"
        :enabled="paymentsStore.payments.data && paymentsStore.payments.data.length > 0"
      />

      <!--Report Date Picker Dialog for Payment Provider Detail-->
      <report-date-picker
        v-if="1 === 2"
        :datePickerSection="paymentSection === 2 ? 3 : 0"
        :enabled="paymentsStore.payments.data && paymentsStore.payments.data.length > 0"
      />

      <export-excel
        :section="22"
        :enabled="paymentsStore.payments.data && paymentsStore.payments.data.length > 0"
      />

      <payments-mass-change />
    </v-toolbar>

    <!-- Delete Dialog Box -->
    <v-dialog v-model="deleteDialog" persistent max-width="500px">
      <v-card>
        <v-card-title>
          <span class="text-h5">Delete Payment?</span>
          <v-spacer />
          <v-btn outlined rounded text @click.native="closeDeleteDialog()"
            ><v-icon small left>fal fa-times</v-icon>Cancel</v-btn
          >
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row dense>
              <v-col cols="12" sm="12">
                {{ deleteMessage }} will be deleted from the system!
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-btn
            block
            rounded
            color="error"
            @click.native="removePayment(recordID)"
            >Delete</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!--Pagination  -->
    <pagination
      :show="paymentsStore.payments.data && paymentsStore.payments.data.length > 0"
      :showTotal="true"
      :currentPage="paymentsStore.payments.current_page"
      :lastPage="paymentsStore.payments.last_page"
      :total="paymentsStore.payments.total"
      v-on:paginate-event="filter($event)"
    />

    <div v-if="progressStore.progress !== true">
      <!--Table-->
      <v-simple-table
        v-if="paymentsStore.payments.data && paymentsStore.payments.data.length > 0"
        :fixed-header="true"
        class="my-2"
      >
        <template #default>
          <thead>
            <tr>
              <th class="text-left grey--text text--darken-1">Client ID</th>
              <th class="text-left grey--text text--darken-1">PreK Seat ID</th>
              <th class="text-left grey--text text--darken-1">Attendance Period</th>
              <th class="text-left grey--text text--darken-1">Client Name</th>
              <th class="text-left grey--text text--darken-1">Client DOB</th>
              <th class="text-left grey--text text--darken-1">Age</th>
              <th class="text-left grey--text text--darken-1">Program</th>
              <th class="text-left grey--text text--darken-1">Program Option</th>
              <th class="text-left grey--text text--darken-1">Reimbursement</th>
              <th class="text-left grey--text text--darken-1">Time</th>
              <th class="text-left grey--text text--darken-1">Parent Fee</th>
              <th class="text-left grey--text text--darken-1">Amount</th>
              <th class="text-left grey--text text--darken-1">Follow Up</th>
              <th class="text-right grey--text text--darken-1">Status</th>
              <th v-if="usersStore.authUser.f_paymentDuplicate === 1">
                <v-btn rounded @click="selectDeselectAll()" color="primary" class="py-0"
                  ><v-icon small left>fal fa-check-double</v-icon>All</v-btn
                >
              </th>
              <th></th>
            </tr>
          </thead>
          <tbody v-for="(item, index) in paymentsStore.payments.data" :key="item.id">
            <tr v-if="item.header">
              <th
                class="text-subtitle-2 text-left grey white--text"
                :colspan="usersStore.authUser.f_paymentDuplicate === 1 ? 17 : 16"
              >
                <span class="pr-4">{{ item.header }}</span>
                <v-tooltip v-if="paymentSection === 1 || paymentSection === 3" top color="primary">
                  <template #activator="{ on }">
                    <v-btn icon v-on="on" @click="detail(1, item.provider.uuid)">
                      <v-icon color="white">
                        fal fa-arrow-alt-square-right
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>Go To Provider</span>
                </v-tooltip>
              </th>
            </tr>
            <tr class="clickable">
              <td
                @click="openEntry(item.id, 0, item.client.f_privateRecord)"
                class="text-left light-blue--text text--darken-4"
                :class="item.followup ? item.followup.d_color : ''"
              >
                {{ item.fk_clientID }}
              </td>
              <td
                @click="openEntry(item.id, 0, item.client.f_privateRecord)"
                class="text-left light-blue--text text--darken-4"
                :class="item.followup ? item.followup.d_color : ''"
              >
                {{ item.d_prekSeatID }}
              </td>
              <td
                @click="openEntry(item.id, 0, item.client.f_privateRecord)"
                class="text-left light-blue--text text--darken-4"
                :class="item.followup ? item.followup.d_color : ''"
              >
                {{ item.f_attendanceMonth }}/{{ item.d_attendanceYear }}
              </td>
              <td
                @click="openEntry(item.id, 0, item.client.f_privateRecord)"
                class="text-left light-blue--text text--darken-4"
                :class="item.followup ? item.followup.d_color : ''"
              >
                {{ item.d_clientNameFirst + " " + item.d_clientNameLast }}
              </td>
              <td
                @click="openEntry(item.id, 0, item.client.f_privateRecord)"
                class="text-left light-blue--text text--darken-4"
                :class="item.followup ? item.followup.d_color : ''"
              >
                {{ $_formatDate(item.d_clientDateBirth) }}
              </td>
              <td
                @click="openEntry(item.id, 0, item.client.f_privateRecord)"
                class="text-left light-blue--text text--darken-4"
                :class="item.followup ? item.followup.d_color : ''"
              >
                {{ item.d_clientAge }}
              </td>
              <td
                @click="openEntry(item.id, 0, item.client.f_privateRecord)"
                class="text-left light-blue--text text--darken-4"
                :class="item.followup ? item.followup.d_color : ''"
              >
                {{ item.d_program }}
              </td>
              <td
                @click="openEntry(item.id, 0, item.client.f_privateRecord)"
                class="text-left light-blue--text text--darken-4"
                :class="item.followup ? item.followup.d_color : ''"
              >
                {{ item.d_programOption }}
              </td>
              <td
                @click="openEntry(item.id, 0, item.client.f_privateRecord)"
                class="text-left light-blue--text text--darken-4"
                :class="item.followup ? item.followup.d_color : ''"
              >
                {{ $_formatMoney(item.d_amountReimbursement) }}
              </td>
              <td
                @click="openEntry(item.id, 0, item.client.f_privateRecord)"
                class="text-left light-blue--text text--darken-4"
                :class="item.followup ? item.followup.d_color : ''"
              >
                {{ item.d_carePlanItemTime }}
              </td>
              <td
                @click="openEntry(item.id, 0, item.client.f_privateRecord)"
                class="text-left light-blue--text text--darken-4"
                :class="item.followup ? item.followup.d_color : ''"
              >
                {{ $_formatMoney(item.d_amountParentFee) }}
              </td>
              <td
                @click="openEntry(item.id, 0, item.client.f_privateRecord)"
                class="text-left light-blue--text text--darken-4"
                :class="item.followup ? item.followup.d_color : ''"
              >
                {{ $_formatMoney(item.d_amount) }}
                <v-badge
                  v-if="item.f_type === 2"
                  :color="item.f_appliedToPayment === 1 ? 'success' : 'error'"
                  icon=""
                  offset-x="-4"
                  offset-y="3"
                >
                </v-badge>
              </td>
              <td
                @click="openEntry(item.id, 0, item.client.f_privateRecord)"
                class="text-left light-blue--text text--darken-4"
                :class="item.followup ? item.followup.d_color : ''"
              >
                <v-icon v-if="item.f_followup" left medium>fal fa-comment-alt-check</v-icon>
              </td>
              <td
                @click="openEntry(item.id, 0, item.client.f_privateRecord)"
                class="text-right light-blue--text text--darken-4"
                :class="item.followup ? item.followup.d_color : ''"
              >
                {{ $_paymentStatus(item.f_status) }}
              </td>
              <td
                 style="text-align: center"
                :class="item.followup ? item.followup.d_color : ''"
              >
                <div style="float:left" v-if="usersStore.authUser.f_paymentDuplicate === 1"
                >
                  <v-tooltip left color="primary">
                    <template #activator="{ on }">
                      <v-btn
                        icon
                        v-on="on"
                        @click="openEntry(item.id, 1, item.client.f_privateRecord)"
                      >
                        <v-icon small color="primary">
                          fal fa-clone
                        </v-icon>
                      </v-btn>
                    </template>
                    <span>Duplicate Payment</span>
                  </v-tooltip>
                </div>
                <div
                v-if="usersStore.authUser.f_admin === 1"
                :class="item.followup ? item.followup.d_color : ''"
                >
                  <v-tooltip left color="error">
                    <template #activator="{ on }">
                      <v-btn
                        icon
                        v-on="on"
                        @click="openDeleteDialog(item.id,
                                                'This payment record for ' +
                                                item.d_clientNameFirst + ' ' + item.d_clientNameLast +
                                                ', in the amount of ' + $_formatMoney(item.d_amount) + ',')"
                      >
                        <v-icon small color="error">
                          fal fa-trash
                        </v-icon>
                      </v-btn>
                    </template>
                    <span>Remove Payment</span>
                  </v-tooltip>
                </div>
              </td>
              <td :class="item.followup ? item.followup.d_color : ''"
                  v-if="usersStore.authUser.f_paymentDuplicate === 1"
              >
                <v-checkbox
                  dense
                  :key="index"
                  v-model="selectedID"
                  :value="item.id"
                  color="primary"
                  multiple
                  class="text-right py-2"
                ></v-checkbox>
              </td>
              <td :class="item.followup ? item.followup.d_color : ''">
                <v-tooltip
                  v-if="
                    (item.client.f_privateRecord === 0 && paymentSection !== 3) ||
                      (usersStore.authUser.f_viewPrivateRecords === 1 &&
                        item.client.f_privateRecord === 1 &&
                        paymentSection !== 3)
                  "
                  top
                  color="primary"
                >
                  <template #activator="{ on }">
                    <v-btn icon v-on="on" @click="detail(2, item.client.uuid)">
                      <v-icon color="primary">
                        fal fa-arrow-alt-square-right
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>Go To Client</span>
                </v-tooltip>
                <v-chip v-if="item.client.f_privateRecord === 1" color="error"
                  ><v-icon small>fal fa-lock</v-icon></v-chip
                >
              </td>
              <td v-if="1 === 2">
                <v-tooltip left color="primary">
                  <template #activator="{ on }">
                    <v-btn
                      icon
                      v-on="on"
                      @click="
                        reprocessPayment(index, item.f_month, item.d_year, item.fk_carePlanItemID)
                      "
                    >
                      <v-icon small color="primary">
                        fal fa-sync-alt
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>{{ item.id }}</span>
                </v-tooltip>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>

      <v-list v-else class="py-2">
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title class="error--text text-subtitle-1 text-xs-center"
              >No Payments Exist</v-list-item-title
            >
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { createHelpers } from "vuex-map-fields";
import { mixin } from "@/mixins/mixin.js";
import TitleBar from "@/components/TitleBar";
import Pagination from "@/components/Pagination";
import PaymentEntry from "@/components/PaymentEntry";
import PaymentsMassChange from "@/components/PaymentsMassChange";
import ExportExcel from "@/components/ExportExcel";
import TextDisplay from "@/components/TextDisplay";
import ReportDatePicker from "@/components/ReportDatePicker";

const { mapFields } = createHelpers({
  getterType: "paymentsStore/getFilterField",
  mutationType: "paymentsStore/updateFilterField"
});

export default {
  name: "Payments",
  components: {
    TitleBar,
    Pagination,
    PaymentEntry,
    PaymentsMassChange,
    ExportExcel,
    TextDisplay,
    ReportDatePicker
  },
  mixins: [mixin],
  computed: {
    ...mapState({
      usersStore: state => state.usersStore,
      paymentsStore: state => state.paymentsStore,
      yearsStore: state => state.yearsStore,
      fundingSourcesStore: state => state.fundingSourcesStore,
      valueListsStore: state => state.valueListsStore,
      programsStore: state => state.programsStore,
      progressStore: state => state.progressStore
    }),
    ...mapFields([
      "page",
      "section",

      "id",
      "fk_providerID",
      "fk_householdID",
      "d_licenseNumber",
      "d_nameLicense",
      "fk_clientID",
      "d_childIDv1",
      "d_prekSeatID",
      "fk_programID",
      "fk_programOptionID",
      "d_nameFirst",
      "d_nameLast",
      "f_month",
      "d_year",
      "f_attendanceMonth",
      "d_attendanceYear",
      "d_fundingCodeSource",
      "f_followup",
      "f_status",
      "noFollowup",
      "selectedID",
      "d_dateStart",
      "d_dateEnd",
      "date1",
      "date2",
      "f_type",
      "clientID",
      "f_viewPrivateRecords",
      "dateStart",
      "dateEnd",
      "date3",
      "date4"
    ])
  },
  props: {
    /* All List = 1, Provider Sublist = 2, Client Sublist = 3 */
    paymentSection: {
      type: Number,
      required: false
    }
  },
  data() {
    return {
      menu: false,
      menu2: false,
      menu3: false,
      menu4: false,
      filterMenu: false,
      deleteDialog: false,
      deleteMessage: "",
      recordID: ""
    };
  },
  created() {
    this.initialize();
  },
  watch: {
    date1() {
      this.d_dateStart = this.$_formatDate(this.date1);
    },
    date2() {
      this.d_dateEnd = this.$_formatDate(this.date2);
    },
    date3() {
      this.dateStart = this.$_formatDateMonth(this.date3);
    },
    date4() {
      this.dateEnd = this.$_formatDateMonth(this.date4);
    }
  },
  methods: {
    async initialize() {
      if (this.paymentSection === 1) {
        this.$store.dispatch("progressStore/set", true);
      }

      await this.changeSectionCheck();

      /* Provider List */
      if (this.paymentSection === 2) {
        const provider = this.$store.getters["providersStore/getProvider"];
        this.fk_providerID = provider.id;
        this.fk_clientID = "";
      } else if (this.paymentSection === 3) {
        /* Client List */
        const client = this.$store.getters["clientsStore/getClient"];
        this.fk_providerID = "";
        this.fk_clientID = client.id;
      } else {
        /* All list */
        this.fk_providerID = "";
        this.fk_clientID = "";
      }

      const user = this.$store.getters["usersStore/getAuthUser"];
      this.f_viewPrivateRecords = user.f_viewPrivateRecords;

      /* Grab filters */
      const filters = this.$store.getters["paymentsStore/getFilters"];
      /* Records based on filters */
      await this.$store.dispatch("paymentsStore/fetch", filters);

      this.$store.dispatch("progressStore/set", false);
    },

    async loadValueLists() {
      await this.$store.dispatch("yearsStore/valueList");
      const vldata = {
        id: [25]
      };
      await this.$store.dispatch("valueListsStore/items", vldata);
      await this.$store.dispatch("programsStore/valueList");
      await this.$store.dispatch("fundingSourcesStore/valueList");
    },

    async filter(page) {
      this.filterMenu = false;
      this.selectedID = [];
      this.page = page;
      this.initialize();
    },

    clearFilterColumns() {
      this.id = "";
      this.fk_householdID = "";
      this.d_licenseNumber = "";
      this.d_nameLicense = "";
      this.d_childIDv1 = "";
      this.d_prekSeatID = "";
      this.fk_programID = "";
      this.d_nameFirst = "";
      this.d_nameLast = "";
      this.f_month = "";
      this.d_year = "";
      this.f_attendanceMonth = "";
      this.d_attendanceYear = "";
      this.d_fundingCodeSource = "";
      this.f_followup = "";
      this.f_status = "";
      this.noFollowup = "";
      this.selectedID = [];
      (this.d_dateStart = ""),
        (this.d_dateEnd = ""),
        (this.date1 = ""),
        (this.date2 = ""),
        (this.f_type = ""),
        (this.fk_programOptionID = ""),
        (this.clientID = "");
      this.dateStart = "";
      this.dateEnd = "";
      this.page = 1;
      this.$store.dispatch("programsStore/clearOptionsValueList");
    },

    async changeSectionCheck() {
      await this.loadValueLists();
      if (this.paymentSection !== this.section) {
        this.clearFilterColumns();
        this.$store.dispatch("paymentsStore/clearPayments");
      }
      /* Save section in store */
      this.section = this.paymentSection;
    },

    async clearFilter() {
      this.filterMenu = false;
      this.clearFilterColumns();
      this.initialize();
    },

    showFiltered() {
      if (
        this.id ||
        this.d_licenseNumber ||
        this.d_nameLicense ||
        this.d_nameFirst ||
        this.d_nameLast ||
        this.clientID ||
        this.fk_householdID ||
        this.d_childIDv1 ||
        this.d_prekSeatID ||
        this.fk_programID ||
        this.f_month ||
        this.d_year ||
        this.f_followup ||
        this.f_status ||
        this.f_status === 0 ||
        this.noFollowup ||
        this.d_dateStart ||
        this.d_dateEnd ||
        this.fk_programID ||
        this.fk_programOptionID ||
        this.f_attendanceMonth ||
        this.d_attendanceYear ||
        this.d_fundingCodeSource ||
        this.f_type ||
        this.dateStart ||
        this.dateEnd
      ) {
        return true;
      }
      return false;
    },

    async openEntry(id, duplicateFlag, privateRecord) {
      const user = this.$store.getters["usersStore/getAuthUser"];
      if (privateRecord === 0 || (privateRecord === 1 && user.f_viewPrivateRecords === 1)) {
        await this.loadValueLists();
        if (duplicateFlag === 1) {
          await this.$store.dispatch("paymentsStore/duplicate", id);
        } else if (user.f_paymentEdit === 1) {
          await this.$store.dispatch("paymentsStore/entry", id);
        }
      } else {
        const data = {
          color: "error",
          text: "Access to this record is prohibited."
        };
        this.$store.commit("messagesStore/setMessage", data);
      }
    },

    selectDeselectAll() {
      this.$store.dispatch("paymentsStore/selectDeselectAll");
    },

    async pullProgramOptions(id) {
      this.fk_programOptionID = "";
      const data = {
        fk_programID: id
      };
      await this.$store.dispatch("programsStore/valueListOptions", data);
    },

    async reprocessPayment(index, month, year, fk_carePlanItemID) {
      const data = {
        id: 2,
        f_month: month,
        d_year: year,
        fk_carePlanItemID
      };
      await this.$store.dispatch("reportsStore/runReport", data);
    },

    detail(mode, uuid) {
      /* Provider */
      let routeData;
      if (mode === 1) {
        routeData = this.$router.resolve({ name: "provider", params: { providerUUID: uuid } });
      } else if (mode === 2) {
        /* Client */
        routeData = this.$router.resolve({ name: "client", params: { clientUUID: uuid } });
      }
      window.open(routeData.href, "_blank");
    },

    openDeleteDialog(id, message) {
      this.deleteMessage = message;
      this.recordID = id;
      this.deleteDialog = true;
    },

    closeDeleteDialog() {
      this.deleteDialog = false;
      this.recordID = '';
      this.deleteMessage = '';
    },

    async removePayment(id) {
      this.closeDeleteDialog();
      const data = {
          id: id
        };
      const response = await this.$store.dispatch("paymentsStore/delete", data);
      let message;
      if (response && response.status === 200) {
        message = {
          color: "success",
          text: "Payment has been deleted."
        };
      } else {
        message = {
          color: "error",
          text: "There is an issue with deleting the payment at this time."
        };
      }
      this.$store.commit("messagesStore/setMessage", message);
    },
  }
};
</script>

<style scoped>
.clickable {
  cursor: pointer;
}
button {
  outline: none;
}
tbody {
  border-bottom: 1px solid #a9a9a9;
}
</style>
